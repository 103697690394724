export default {
    namespaced: true,

    state: () => {
        return {
            loadingSections: false,
            sections: [],
        }
    },

    getters: {
        loadingSections(state) {
            return state.loadingSections;
        },

        sections(state) {
            return state.sections;
        },
    },

    mutations: {
        loadingSections(state, isLoading) {
            state.loadingSections = isLoading;
        },

        addSections(state, sections) {
            state.sections = sections;
        },
    },

    actions: {
        async getSections(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingSections', true);

            let sections = await axios.get(`/get_sections`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingSections', false);

            if(options.returnData) {
                return sections;
            }
            
            ctx.commit('addSections', sections);
        },

        async saveSection(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.section)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getSections');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteSection(ctx, id) {
            await axios.post(`/delete-section`, {id})
            .then(res => {
                ctx.dispatch('getSections');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
