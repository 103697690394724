import Vue from "vue";
import Vuex from "vuex";
import employee from "./employee";
import area from "./area";
import country from "./country";
import snackbar from "./snackbar";
import department from "./department";
import shift from "./shift";
import designation from "./designation";
import month from "./month";
import user from "./user";
import attendance from "./attendance";
import leave from "./leave";
import bank from "./bank";
import general from "./general";
import holiday from "./holiday";
import company from "./company";
import promotion from "./promotion";
import increment from "./increment";
import loan from "./loan";
import device from "./device";
import transaction from "./transaction";
import pf from "./pf";
import salary from "./salary";
import bonus from "./bonus";
import notice from "./notice";
import section from "./section";

Vue.use(Vuex);

export default new Vuex.Store({
    state: () => {
        return {
            host:
                process.env.NODE_ENV === "production"
                    ? "https://funfactoryhr.com/public/"
                    : "http://127.0.0.1:8000/"
        };
    },

    getters: {},

    mutations: {},

    actions: {},

    modules: {
        employee,
        area,
        snackbar,
        department,
        designation,
        section,
        month,
        user,
        attendance,
        leave,
        bank,
        shift,
        general,
        holiday,
        company,
        country,
        promotion,
        increment,
        loan,
        device,
        transaction,
        salary,
        pf,
        bonus,
        notice
    }
});
