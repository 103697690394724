export default {
    namespaced: true,

    state: () => ({
        loadingLeaveTypes: false,
        loadingLeaves    : false,
        leaves           : [],
        leave_list       : [],
        leaveTypes       : [],
    }),

    getters: {
        loadingLeaves(state) {
            return state.loadingLeaves;
        },
        
        loadingLeaveTypes(state) {
            return state.loadingLeaveTypes;
        },

        leaves(state) {
            return state.leaves.map((leave, ind) => {
                leave.sl = ind + 1;
                leave.leave_type_name = leave.leave_type.name;
                leave.from_date = leave.leave_dates.length > 0 ? leave.leave_dates[0].leave_date : '';
                leave.to_date = leave.leave_dates.length > 0 ? leave.leave_dates[leave.leave_dates.length - 1].leave_date : '';
                return leave;
            });
        },

        leaveTypes(state){
            return state.leaveTypes
        },
        leave_list(state){
            return state.leave_list
        },
    },

    mutations: {
        loadingLeaveTypes(state, isLoading) {
            state.loadingLeaveTypes = isLoading;
        },

        loadingLeaves(state, isLoading) {
            state.loadingLeaves = isLoading;
        },

        addLeaves(state, leaves) {
            state.leaves = leaves;
        },
        
        addLeaveTypes(state, types) {
            state.leaveTypes = types;
        },
        leave_list(state, leave_list) {
            state.leave_list = leave_list;
        },

    },

    actions: {
        async saveLeaveType(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.leave_type)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getLeaveTypes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },
        async deleteLeaveType(ctx, id) {
            await axios.post(`/delete_leave_type`, {id})
            .then(res => {
                ctx.dispatch('getLeaveTypes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },
        async getLeaveTypes(ctx, { apiParams = {}, options = {} } = {}){

            ctx.commit('loadingLeaveTypes', true);

            let types = await axios.post(`/get_leave_types`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingLeaveTypes', false);

            if(options.returnData) {
                return types;
            }
            
            ctx.commit('addLeaveTypes', types);

        },
        async saveLeave(ctx, leaveData) {
            let returnData = {isSuccess: false};

            let url = 'add-leave';
            if(leaveData.leave.id != null) {
                url = 'update-leave'
            }

            await axios.post(`/${url}`, leaveData)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },

        async deleteLeave(ctx, id) {
            let isSuccess = false;

            await axios.post(`/delete-leave`, { id })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
                isSuccess = false;
            })

            return isSuccess;
        },

        async getLeaves(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingLeaves', true);

            let leaves = await axios.post(`/get-leaves`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            ctx.commit('loadingLeaves', false);

            if(options.returnData) {
                return leaves;
            }

            ctx.commit('addLeaves', leaves);
        },
        async getLeaveList(ctx, { apiParams = {}, options = {} } = {}) {

            let leave_list = await axios.post(`/get_leave_list`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            if(options.returnData) {
                return leave_list;
            }

            ctx.commit('leave_list', leave_list);
        },

        async getLeaveSummary(ctx, {employeeId = null, data = {}}) {
            let summary = await axios.post(`/get_type_wise_leave/${employeeId}`, data)
            .then(res => {
                return res.data;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return summary;
        },
    }
}
